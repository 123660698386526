import "../App.css";

import NavBar from "./NavBar";

import Footer from "./Footer";

import BlogContent from "../JSON/Early.json";

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInRight } from 'react-animations';
import { useNavigate } from "react-router-dom";

const FadeInRight = styled.div`animation: ${({ animationTime }) => `${animationTime}s`} ${keyframes`${fadeInRight}`}`;

function UpdateSmall(props) {
  const navigate =  useNavigate()
  return (
    <div className="UpdateSmall">
      <article>
        <img
          src={BlogContent[2].data.find(item => item.id == props.id)["thumbnail"]}
          alt="Project Image"
          style={{ margin: "20px", maxWidth: "40%", maxHeight: "40%" }} 
        />
        <div>
          <h1>{BlogContent[2].data.find(item => item.id == props.id)["name"]}: </h1>
          <br />
          <img
            src={BlogContent[2].data.find(item => item.id == props.id)["thumbnail"]}
            alt="Project Image"
            style={{ margin: "20px", " width": "50%" }}
            className="MImg"
          />
          <p>
            {BlogContent[2].data.find(item => item.id == props.id)["presentation-text"]}
          </p>
          <label>{BlogContent[2].data.find(item => item.id == props.id)["date"]}</label><br/>
          {(BlogContent[2].data.find(item => item.id == props.id)["text-content"])?<a onClick={ () => {navigate("/E"+props.id)}}>Learn more</a>:<br/>}
        </div>
      </article>
    </div>
  );
}





function App() {

  return (
    <div className="Updates"> 
      <NavBar />
      <section className="ProjectsList">
        <h1>Earlier projects and beginnings: </h1>
        <br/>
        {BlogContent[2].data.map((element, i) => {
          return <FadeInRight animationTime={1+(i*0.2)}><UpdateSmall User key={i} id={element.id} /></FadeInRight>;
        })}
      </section>
      <Footer />
    </div>
  );
}

export default App;
