import "./App.css";
import HomePage from "./Components/HomePage.js";
import Updates from "./Components/Updates.js";
import Certificates from "./Components/Certificates.js";
import { Routes, Route } from "react-router-dom";
import Drums from "./Components/Drums.js";
import Contact from "./Components/Contact.js";
import Earlier from "./Components/Earlier.js";
import ScrollTop from "./Components/ScrollTop.js";

import UpdatePage0 from "./Components/UpdatePages/UpdatePage0.js";
import UpdatePage1 from "./Components/UpdatePages/UpdatePage1.js";
import UpdatePage2 from "./Components/UpdatePages/UpdatePage2.js";
import UpdatePage3 from "./Components/UpdatePages/UpdatePage3.js";
import UpdatePage4 from "./Components/UpdatePages/UpdatePage4.js";
import UpdatePage5 from "./Components/UpdatePages/UpdatePage5.js";
import UpdatePage6 from "./Components/UpdatePages/UpdatePage6.js";
import UpdatePage7 from "./Components/UpdatePages/UpdatePage7.js"
import UpdatePage8 from "./Components/UpdatePages/UpdatePage8.js"
import UpdatePage9 from "./Components/UpdatePages/UpdatePage9.js"
import UpdatePage10 from "./Components/UpdatePages/UpdatePage10.js"
import UpdatePage11 from "./Components/UpdatePages/UpdatePage11.js"
import UpdatePage12 from "./Components/UpdatePages/UpdatePage12.js"
import UpdatePage13 from "./Components/UpdatePages/UpdatePage13.js"

import UpdatePageE1 from "./Components/UpdatePages/UpdatePageE1.js";
import UpdatePageE3 from "./Components/UpdatePages/UpdatePageE3.js";
import UpdatePageE4 from "./Components/UpdatePages/UpdatePageE4.js";

function App() {
  return (
    <div className="App">
    <ScrollTop/>
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Updates" element={<Updates />} />
          <Route path="/Certificates" element={<Certificates />} />
          <Route path="/Drums" element={<Drums />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Earlier" element={<Earlier />} />

          <Route path="/0" element={<UpdatePage0 />} />
          <Route path="/1" element={<UpdatePage1 />} />
          <Route path="/2" element={<UpdatePage2 />} />
          <Route path="/3" element={<UpdatePage3 />} />
          <Route path="/4" element={<UpdatePage4 />} />
          <Route path="/5" element={<UpdatePage5 />} />
          <Route path="/6" element={<UpdatePage6 />} />
          <Route path="/7" element={<UpdatePage7 />} />
          <Route path="/8" element={<UpdatePage8 />} />
          <Route path="/9" element={<UpdatePage9 />} />
          <Route path="/10" element={<UpdatePage10 />} />
          <Route path="/11" element={<UpdatePage11 />} />
          <Route path="/12" element={<UpdatePage12 />} />
          <Route path="/13" element={<UpdatePage13 />} />

          <Route path="/E1" element={<UpdatePageE1 />} />
          <Route path="/E3" element={<UpdatePageE3 />} />
          <Route path="/E4" element={<UpdatePageE4 />} />

          <Route path="*" component={<HomePage />} />
      </Routes>
    </div>
  );
}

export default App;
