import "../App.css";

import NavBar from "./NavBar";

import Footer from "./Footer";

import UpdateSmall from "./UpdateSmall";

import BlogContent from "../JSON/BlogContent.json";

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInRight } from 'react-animations';
import { useNavigate } from "react-router-dom";
const FadeInRight = styled.div`animation: ${({ animationTime }) => `${animationTime}s`} ${keyframes`${fadeInRight}`}`;
function App() {
  const navigate =  useNavigate()
  return (
    <div className="Updates"> 
      <NavBar />
      <section className="ProjectsList">
        <h1>Updates: </h1>
        <br/>
        {BlogContent[2].data.map((element, i) => {
          return <FadeInRight animationTime={1+(i*0.2)}><UpdateSmall User key={i} id={element.id} /></FadeInRight>;
        })}
<FadeInRight animationTime={1+(BlogContent[2].data.length*0.2)}><div className="UpdateSmall" >
      <article>
        <img
          src={"./Content/Images/Earlier.png"}
          alt="Project Image"
          style={{ margin: "20px", maxWidth: "40%", maxHeight: "40%" }} 
        />
        <div>
          <h1>Earlier projects and beginnings: </h1>
          <br />
          <img
            src={"./Content/Images/Earlier.png"}
            alt="Project Image"
            style={{ margin: "20px", " width": "50%" }}
            className="MImg"
          />
          <p>
            This section of the website contains older projects and how i got into programming and robotics.
          </p>
          <label> 2016-2020 </label><br/>
          <a onClick={ () => {navigate("/Earlier")}}>Learn more</a>
        </div>
      </article>
    </div></FadeInRight>



      </section>
      <Footer />
    </div>
  );
}

export default App;
