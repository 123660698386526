import React, {useState} from 'react'
import './Slider.css'
import BtnSlider from './BtnSlider'
export default function Slider(props) {

    const [slideIndex, setSlideIndex] = useState(1)
    let flag = false
    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    let content = JSON.parse(props.Content)
    let dataSlider = []
    if(content.Images)
    {    
    for (let i = 0; i < content.Images.length; i++){
        dataSlider.push((content.Images[i]))
    }
    }
    if(content.Videos)
    {    
    for (let i = 0; i < content.Videos.length; i++){
        dataSlider.push((content.Videos[i]))
    }
    }

    return (
        <div className={"container-slider "} >
            {dataSlider.map((obj, index) => {
                if(slideIndex === index + 1)
                {
                    if(slideIndex >= content.Images.length+1)
                    {
                        flag = true
                        return (                    <div
                            key={obj.id}
                            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                            >
                            <video controls={flag}><source src={dataSlider[index]} type="video/mp4" />Your browser does not support the video tag.</video>
                            </div>)
                    }
                    else
                    {
                        flag = false                
                        return (
                            <div
                            key={obj.id}
                            className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                            >
                            <img src={dataSlider[index]} />
                            </div>
                        )
                    }
                }
                else{
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                    <img src={dataSlider[index]} />
                    </div>
                )}
            })}
            <BtnSlider moveSlide={nextSlide} direction={"next"} />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}/>

            <div className="container-dots">
                {Array.from({length: dataSlider.length}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
        </div>
    )
}
