import "../App.css";
import DifferentInfo from "../JSON/DifferentInfo.json";
import NavBar from "./NavBar";

import  ProfileImage from "../Content/Images/ProfileImage.jpg";

import Footer from "./Footer";

import { useNavigate } from "react-router-dom";
function App() {
    const navigate =  useNavigate()
    return (
    <div className="App">
    
    <NavBar/>
    <main>
        <section id="AboutMe"><img id="AboutMeImg" src={DifferentInfo[2].data[0]["AboutMeImage"]} alt="My photo"
                 style={{marginLeft: "25px", marginRight: "60px", height: "400px", width: "auto"}} />
            <div>
                <br/>
                <h1>About me:</h1>
                <img src={ProfileImage} alt="My photo"
                 style={{marginLeft: "25px", marginRight: "60px", height: "400px", width: "auto"}} className="MImg"/>
                <p>{DifferentInfo[2].data[0]["AboutMeText"]}</p>
            </div>

        </section>

        <section id="Skills">
            <div className="Skill" style={{animationDuration: "1s", animationName: "fadeInTop"}}>
                <h1>Robotics</h1><br/>
                <p>{DifferentInfo[2].data[0]["Robotics_present"]}</p><a
                    onClick={ () => {navigate("/Updates")}}>Learn more</a>
            </div>
            <div className="Skill" style={{animationDuration: "1.2s", animationName: "fadeInTop"}}>
                <h1>Informatics</h1><br/>
                <p>{DifferentInfo[2].data[0]["Informatics_present"]}</p><a onClick={ () => {navigate("/Updates")}}>Learn
                    more</a>
            </div>
            
            <div className="Skill" style={{animationDuration: "1.4s", animationName: "fadeInTop"}}>
                <h1>Drums</h1><br/>
                <p>{DifferentInfo[2].data[0]["Drums_present"]}</p><a onClick={ () => {navigate("/Drums")}}>Learn
                    more</a>
            </div>
        </section>
  </main>
    <Footer />
    </div>
  );
}

export default App;