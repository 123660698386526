import DifferentInfo from "../JSON/DifferentInfo.json";
import { useNavigate } from "react-router-dom";
function NavBar() {
    const navigate =  useNavigate()
  return <div className="NavBar">
    <div>
          <nav style={{ marginBottom: "200px" }}>
            <a onClick={ () => {navigate("/")}}>
              <img
                src={DifferentInfo[2].data[0]["Logo"]}
                alt="Logo Image"
                style={{ marginLeft: "25px" }}
              />
            </a>
            <div className="NavLinksDiv" style={{width: "40%"}}>
              <a onClick={ () => {navigate("/")}} className="NavLinks">
                Home
              </a>
              <a onClick={ () => {navigate("/Updates")}}  className="NavLinks"  >
                Updates
              </a>
              <a onClick={ () => {navigate("/Certificates")}}  className="NavLinks"  >
                Certificates
              </a>
              <a onClick={ () => {navigate("/Drums")}}  className="NavLinks"  >
                Drums
              </a>
              <a onClick={ () => {navigate("/Contact")}}  className="NavLinks"  >
                Contact
              </a>
            </div>
          </nav>
        </div>
    </div>;
}

export default NavBar;

/*import DifferentInfo from "../JSON/DifferentInfo.json";
import { useNavigate } from "react-router-dom";
function NavBar() {
  const  navigate = useNavigate();
  return <div className="NavBar">
    <div>
          <nav style={{ marginBottom: "200px" }}>
            <button onClick={ () => {navigate("/")}}>
              <img
                src={DifferentInfo[2].data[0]["Logo"]}
                alt="Logo Image"
                style={{ marginLeft: "25px" }}
              />
            </button>
            <div className="NavLinksDiv">
              <button onClick={ () => {navigate("/")}} className="NavLinks">
                Home
              </button>
              <button onClick={ () => {navigate("/Updates")}}  className="NavLinks">
                Updates
              </button>
              <button onClick={ () => {navigate("/Certificates")}}  className="NavLinks">
                Certificates
              </button>
              <button onClick={ () => {navigate("/Drums")}}  className="NavLinks">
                Drums
              </button>
              <button onClick={ () => {navigate("/Contact")}}  className="NavLinks">
                Contact
              </button>
            </div>
          </nav>
        </div>
    </div>;
}

export default NavBar;
*/