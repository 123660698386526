import DifferentInfo from "../JSON/DifferentInfo.json";
function Footer() {
  return (
    <div className="Footer">
      <footer>
        <h2>All Rights Reserved {DifferentInfo[2].data[0]["YearOfCR"]}&#169; </h2>
        <h2>Version 1.3.2</h2>
        <h2>{DifferentInfo[2].data[0]["ContactEmail"]}</h2>
      </footer>
    </div>
  );
}

export default Footer;
